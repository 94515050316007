body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swal2-icon.swal2-error {
  border-color: #912e2d !important;
  color: #912e2d !important;
}

.swal2-icon.swal2-warning {
  border-color: #912e2d !important;
  color: #912e2d !important;
}

.swal2-icon.swal2-info {
  border-color: #912e2d !important;
  color: #912e2d !important;
}

.swal2-icon.swal2-success {
  border-color: #912e2d !important;
  color: #912e2d !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #912e2d !important;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #912e2d !important;
}

.swal2-success-circular-line-left,
.swal2-success-fix,
.swal2-success-circular-line-right {
  background-color: transparent !important;
}

.swal2-confirm {
  background-color: #912e2d !important;
}

.box {
  /* width: 200px; */
  /* height: 300px; */
  position: relative;
  border: 1px solid #bbb;
  background: #eee;
  /* float: left; */
  /* margin: 20px; */
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}

.ribbon span {
  font-size: 0.8rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px black;
  position: absolute;
  top: 17px;
  right: -29px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.yellow span {
  background: linear-gradient(#f9e154 0%, #f5c600 100%);
  color: #7e7e7e;
}

.yellow span::before {
  border-left-color: #f5c600;
  border-top-color: #f5c600;
}

.yellow span::after {
  border-right-color: #f5c600;
  border-top-color: #f5c600;
}
