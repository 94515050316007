.sidebar-show {
  @include media-breakpoint-down(lg) {
    overflow: hidden;

    .sidebar {
      left: 0;
      opacity: 1;
      visibility: visible;
    }

    .header-main,
    .main-mobile-header,
    .main {
      transform: translateX($sidebar-width + 40px);
    }

    .main-backdrop {
      opacity: 1;
      visibility: visible;
    }
  }
}

.sidebar-offset {
  .sidebar {
    left: -$sidebar-width;
    box-shadow: none;
  }

  .header-main {
    left: 0;
  }
  .main {
    margin-left: 0;
  }

  &.sidebar-show {
    overflow: hidden;

    .sidebar {
      left: 0;
      box-shadow:
        1px 0 1px rgba($gray-900, 0.01),
        4px 0 4px rgba($gray-900, 0.01),
        16px 0 16px rgba($gray-900, 0.01);
    }

    .header-main,
    .main {
      transform: translateX($sidebar-width);
    }

    .main-backdrop {
      opacity: 1;
      visibility: visible;
    }
  }
}

.sidebar-hide {
  @include media-breakpoint-up(lg) {
    .sidebar {
      left: -$sidebar-width;
      opacity: 0;
      visibility: hidden;
    }

    .header-main {
      left: 0;
      border-left-width: 0;
    }

    .main {
      margin-left: 0;
    }

    .music-player {
      left: 23px;
    }
  }
}

.sidebar {
  width: $sidebar-width + 40px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -($sidebar-width + 40px);
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  z-index: 1000;
  @include transition(all 0.25s);

  @include media-breakpoint-up(lg) {
    width: $sidebar-width - 10px;
    opacity: 1;
    visibility: visible;
    left: 0;
    box-shadow:
      1px 0 1px rgba($gray-900, 0.01),
      4px 0 4px rgba($gray-900, 0.01),
      16px 0 16px rgba($gray-900, 0.01);
  }

  @include media-breakpoint-up(xxl) {
    width: $sidebar-width;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#fff, 0.6);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.25s);
  }

  &.footer-menu-show {
    &::before {
      visibility: visible;
      opacity: 1;
    }

    .sidebar-footer {
      bottom: 0;
    }
  }
}

.sidebar-header {
  padding: 0 20px;
  // height: $height-header;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-200;
  flex-direction: column;
}

.sidebar-logo {
  @extend .header-logo;
}

.sidebar-body {
  height: calc(100% - #{$height-header + 64px});
  position: relative;

  .nav-group {
    border-bottom: 1px solid $gray-200;

    &.show {
      .nav-label::after {
        content: "\EA4E";
      }
      .nav-sidebar {
        display: block;
      }
    }
  }

  .nav-label {
    display: block;
    padding: 12px 20px;
    font-size: 11px;
    font-family: $ff-secondary;
    text-transform: uppercase;
    color: $gray-600;
    letter-spacing: 0.7px;
    position: relative;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $gray-600;
    }

    &::after {
      content: "\EA6E";
      font-family: "remixicon";
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      top: 50%;
      right: 15px;
      line-height: 0;
      opacity: 0.6;
    }
  }

  .nav-sidebar {
    display: none;
    padding: 0 0 20px;

    .nav-link {
      padding: 8px 20px;
    }

    .nav-item.show .nav-sub {
      display: block;
    }
  }

  > .ps__rail-y {
    background-color: $border-color;
    width: 2px;

    > .ps__thumb-y {
      background-color: $secondary;
      width: 2px;
      left: 0;
    }
  }
}

.sidebar-footer {
  position: absolute;
  bottom: -250px;
  left: 0;
  right: 0;
  border-top: 1px solid $gray-200;
  background-color: #fff;
  z-index: 10;
  box-shadow: -20px -20px 20px #fff;
  @include transition(all 0.25s);

  .dropdown-link {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: $secondary;
    opacity: 0.6;
    @include transition(all 0.25s);

    &:hover,
    &:focus {
      background-color: rgba($gray-200, 0.6);
      opacity: 1;
    }
  }
}

.sidebar-footer-top {
  height: 64px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  padding-left: 15px;
}

.sidebar-footer-thumb {
  width: $height-base;
  height: $height-base;
  border-radius: 100%;
  margin-right: 10px;
  @include transition(all 0.25s);

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}

.sidebar-footer-body {
  flex: 1;

  h6 {
    margin-bottom: 2px;
    font-weight: 600;

    a {
      color: $dark;
    }
  }

  p {
    margin-bottom: 0;
    font-size: $font-size-xs;
    color: $secondary;
  }
}

.sidebar-footer-menu {
  padding: 10px 12px;

  .nav {
    flex-direction: column;
    margin: 0;
  }

  a {
    height: 35px;
    padding: 0 8px;
    color: $gray-700;
    display: flex;
    align-items: center;
    border-radius: 3px;
    @include transition(all 0.25s);

    &:hover {
      background-color: $gray-200;
      color: $gray-900;
    }
  }

  a > i {
    line-height: 1;
    font-size: 20px;
    margin-right: 12px;
  }

  hr {
    margin: 10px 10px;
    opacity: 0.12;
  }
}

.sidebar-right {
  width: 290px;
}

.sidebar-label {
  display: block;
  margin-bottom: 5px;
  padding: 0 10px;
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  color: $secondary;
}
