.accordion-item {
  border-color: $gray-300;
  background-color: #fff;
}

.accordion-button {
  background-color: #fcfcfc;
  font-weight: $font-weight-medium;

  &:not(.collapsed) {
    background-color: rgba($gray-200, 0.6);
    box-shadow: inset 0 -1px 0 $gray-300;
  }

  &::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
    opacity: 0.6;
  }
}

.accordion-custom {
  .accordion-item {
    + .accordion-item {
      margin-top: 5px;
    }

    &:not(:first-of-type) {
      border-top: 1px solid $gray-300;
    }
  }

  .accordion-button {
    background-color: $gray-100;

    &:not(.collapsed) {
      background-color: #fff;
      box-shadow: none;
    }
  }

  .accordion-body {
    padding-top: 0;
  }
}

.accordion-primary {
  .accordion-item {
    border-color: $primary;
    border-width: 1.5px;

    + .accordion-item {
      margin-top: 5px;
    }
    &:not(:first-of-type) {
      border-top: 1.5px solid $primary;
    }
  }

  .accordion-button {
    background-color: $primary;
    color: #fff;

    &::after {
      content: "\EA4E";
      font-family: "remixicon";
      font-size: 18px;
      font-weight: 400;
      background-image: none;
    }

    &:not(.collapsed) {
      box-shadow: none;
      background-color: #fff;
      color: $primary;

      &::after {
        opacity: 1;
      }
    }
  }

  .accordion-body {
    padding-top: 0;
  }
}
