.config-sidebar {
  background-color: #f4f4f9;
  max-width: 200px;
  border-right: 1px solid #dee2e6; /* Divider between sidebar and content */
}

.config-content {
  padding: 20px;
  background-color: white;
  height: 300px; /* Set a fixed height or make it dynamic */
  overflow-y: auto; /* Enable scroll if content is long */
  min-height: 750px;
}

.list-group-item {
  cursor: pointer;
  border: none; /* Remove borders from list items for a cleaner look */
}

.list-group-item:hover,
.list-group-item:focus {
  background-color: #eceff1; /* Lighter shade for hover/focus */
}

.list-group-item.active {
  background-color: #912e2d; /* Active item background color */
  color: white;
}

.modal-content {
  border-radius: 10px; /* Rounded corners for modal */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Shadow for premium look */
}

.list-group-item.active {
  background-color: #912e2d; /* Active item background color */
  color: white;
}
